import React from 'react';
import './footer.scss';

class Footer extends React.Component{
  render(){
    return(
      <footer>
        <p>@copyright Lena Eivy 2019</p>
      </footer>
    );
  }
}

export default Footer;